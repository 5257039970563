<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      v-if="user && user.role"
      class="bookmark-wrapper align-items-left flex-grow-1 d-none d-lg-flex"
    >
      <b-button
        v-if="['admin', 'director', 'cashier'].includes(user.role.name)"
        variant="success"
        @click="payment('contracts')"
      >
        $ {{ $t('Оплата') }}
      </b-button>
    </div>
    <!-- scanner -->
    <div class="align-items-left flex-grow-1 d-none d-lg-flex">
      <b-button
        variant="primary"
        @click="openScannerModal = true"
      >
        <feather-icon icon="CreditCardIcon" />
        {{ $t('Сканер продукта') }}
      </b-button>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
        v-can="'marketplace.quick_orders-menu'"
        variant="warning"
        class="mr-1"
        @click="goTo('QuickOrdersList')"
      >
        <feather-icon icon="BellIcon" />
        {{ $t('Buyurtmalar') }}
      </b-button>
      <!-- <locale /> -->
      <div>USD <b class="text-primary">{{ dollarRateReal|formatPrice }}</b></div>
      <dark-toggler class="d-none d-lg-block" />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
    <search-for-payment
      ref="searchForPayment"
      :type="paymentType"
    />
    <div v-if="openScannerModal">
      <ScannerProduct @close="openScannerModal = false" />
    </div>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import SearchForPayment from '@/views/payment/search-for-payment.vue'
import ScannerProduct from '@/components/modals/scannerProduct.vue'
import { mapActions } from 'vuex'
import { setItem } from '@/utils/storage'
import DarkToggler from './components/DarkToggler.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    SearchForPayment,
    BLink,
    // Navbar Components
    ScannerProduct,
    BNavbarNav,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      paymentType: 'contracts',
      openScannerModal: false,
      dollarRateReal: 0,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/USER']
    },
  },
  mounted() {
    this.getRealDollarRates({ current_dollar_rate: 1 }).then(res => {
      if (res.data) {
        this.dollarRateReal = res.data.data[0]?.amount
        setItem('realDollarRate', res.data.data[0]?.amount)
      }
    })
  },
  methods: {
    ...mapActions({
      getRealDollarRates: 'resource/getRealDollarRates',
    }),
    payment(type) {
      this.paymentType = type
      this.$refs.searchForPayment.show = true
    },
  },
}
</script>
